import styles from 'styles/components/Welcome.module.css';
import welcomeImg from 'assets/img/welcome.png';
import todoImg from 'assets/img/todo.png';
import arrowImg from 'assets/img/arrow.png';

import sandglassImg from 'assets/img/sandglass.png';

import Header from './Header';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Welcome() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    navigate('/general' + search);
  };

  let version = searchParams.get('short') === 'true' ? 'short' : 'long';

  const numOfQuestions = version === 'short' ? 30 : 90;
  const estimatedTime = version === 'short' ? 30 : 90;

  return (
    <>
      <div className={styles.Welcome}>
        <Header borderless={true} />
        <div className={styles.Welcome__container}>
          <img className={styles.Welcome__introImg} src={welcomeImg} alt='' />
          <p
            className={styles.Welcome__title}
            dangerouslySetInnerHTML={{
              __html: t('WELCOME_TITLE'),
            }}
          />
          <p className={styles.Welcome__instructions}>{t('WELCOME_P1')}</p>
          <p className={styles.Welcome__instructions}>
            {i18n.t('WELCOME_P2', {
              estimatedTime: estimatedTime,
            })}
          </p>
          <p className={styles.Welcome__instructions}>{t('WELCOME_P3')}</p>
          <p className={styles.Welcome__instructions}>
            <a
              className={styles.Welcome__link}
              href='/assets/DEG-digital-maturity-survey-tc.pdf'
              rel='noopener'
              target='_blank'
            >
              {t('WELCOME_TC_LINK')}
            </a>
            {' ' + t('WELCOME_TC_ENDING')}.
          </p>
          <div className={styles.Welcome__guide}>
            <div className={styles.Welcome__guideItem}>
              <img className={styles.Welcome__guideImg} src={todoImg} alt='' />
              <p
                dangerouslySetInnerHTML={{
                  __html: i18n.t('WELCOME_SURVEY_INCLUDES', {
                    numOfQuestions: numOfQuestions,
                  }),
                }}
              />
            </div>
            <img className={styles.Welcome__arrowImg} src={arrowImg} alt='' />

            <div className={styles.Welcome__guideItem}>
              <img
                className={styles.Welcome__guideImg}
                src={sandglassImg}
                alt=''
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: i18n.t('WELCOME_SURVEY_TAKES', {
                    estimatedTime: estimatedTime,
                  }),
                }}
              />
            </div>
          </div>
          <button
            type='button'
            className={styles.Welcome__button}
            onClick={handleClick}
          >
            {t('WELCOME_START_BTN')}
          </button>
        </div>
      </div>
    </>
  );
}

export default Welcome;
