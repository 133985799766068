import { useTranslation } from 'react-i18next';
import styles from 'styles/components/Invalid.module.css';

function Invalid() {
  const { t } = useTranslation();

  return (
    <div className={styles.Invalid}>
      <p className={styles.Text}>{t('INVALID_LINK')}</p>
    </div>
  );
}

export default Invalid;
