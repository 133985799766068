import axios from 'axios';

const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJpYXQiOjE2NDM5MDU0MzcuODAzNDM2LCJhdWQiOiJwYXQiLCJqdGkiOiJlNjYxNzQzNi05ZmZlLTQ0MWMtOWI0Yy0yMTgyYjQ2ZjU3ZmQtMThiZjk3YTMtNjFhZS00MjE0LWIyOTMtZTU3ZDkyYzEyN2Y2LTllNmY4M2YxLWYwNDQtNDQwNy04ZWI2LTVmOTI3YjQzMGQ1ZCIsImlzcyI6Imh0dHA6Ly9zdXJ2aW8uY29tIiwic3ViIjoiaHR0cDovL2FwaS5zdXJ2aW8uY29tIiwibGFuZ3VhZ2UiOiJjcyIsInVzZXJJZCI6NDAzMTI3Mywic2VydmljZVR5cGUiOiJGcmVlIn0.qX2hwPwMNQn6eF9X5DT-UOoaCWS8JezD5-6xykaL5T05MTt8priUOT8sSA8g0LwSgA6MHCrJN5_vkhNki0ysGJT56qP1Q14EqiH_abLBffctmrVqj3ZHgsjVE14nw0aq4f8K58hRdZublre6u6jB74to-L36XQaZ4vZG0Ky2bcDIpwqkTWdIMOZKBCaPMCMmSOQuIhdQsBSp_PlBUTI-IBcnNBAEHxPNlNIZ1cXuI5diKg8yoccaMOm7tjtXPFIZtSSgutXaAxP8N_TN6gKz9e5YYaRsF5egp5u2sXJeGM_gGuPhA1p8tz9xMrhK2S56hr_i66GWeK-9UqcVaoSD5Q';

let config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  },
};

const get = (endpoint) => {
  return axios.get(endpoint, config);
}

export default { get };