import styles from 'styles/components/Ending.module.css';
import Header from './Header';
import check from 'assets/img/check.svg';
import { useTranslation } from 'react-i18next';

function Ending({ short }) {
  const { t } = useTranslation();

  console.log(short);

  return (
    <>
      <div className={styles.Ending}>
        <Header borderless={true} />
        <div className={styles.Ending__container}>
          <img className={styles.Check} src={check} alt='Check' />
          <p className={styles.Title}>{t('ENDING_TITLE')}</p>
          <p className={styles.Subtitle}>
            {short ? t('ENDING_TEXT_SHORT') : t('ENDING_TEXT_LONG')}
          </p>
        </div>
      </div>
    </>
  );
}

export default Ending;
