import { getVariant, generalSurvey } from 'constants/decisions';

const isCustomerValid = (responses, customerId) => {
  let customerResponses = getCustomerResponses(responses, customerId);
  if (customerResponses.length === 0) return false;
  return true;
};

const evaluatingNextCustex = (language, responses, customerId, version) => {
  let response = getCustomerResponses(responses, customerId)[0];

  let answerObj = response['answers'].find(
    (el) => 
      el.question.id === generalSurvey.enLong.questionSegments ||
      el.question.id === generalSurvey.esLong.questionSegments ||
      el.question.id === generalSurvey.frLong.questionSegments ||
      el.question.id === generalSurvey.enShort.questionSegments ||
      el.question.id === generalSurvey.esShort.questionSegments ||
      el.question.id === generalSurvey.frShort.questionSegments
  );

  let choice = '';
  if (answerObj !== 'undefined') {
    let ids = answerObj.answer.ids;
    choice += ids.includes(generalSurvey.enLong.answer1Retail) ||
      ids.includes(generalSurvey.esLong.answer1Retail) ||
      ids.includes(generalSurvey.frLong.answer1Retail) ||
      ids.includes(generalSurvey.enShort.answer1Retail) ||
      ids.includes(generalSurvey.esShort.answer1Retail) ||
      ids.includes(generalSurvey.frShort.answer1Retail)
      ? '1' : '0';
    choice += ids.includes(generalSurvey.enLong.answer2SME) ||
      ids.includes(generalSurvey.esLong.answer2SME) ||
      ids.includes(generalSurvey.frLong.answer2SME) ||
      ids.includes(generalSurvey.enShort.answer2SME) ||
      ids.includes(generalSurvey.esShort.answer2SME) ||
      ids.includes(generalSurvey.frShort.answer2SME)
      ? '1' : '0';
    choice += ids.includes(generalSurvey.enLong.answer3Corporate) ||
      ids.includes(generalSurvey.esLong.answer3Corporate) ||
      ids.includes(generalSurvey.frLong.answer3Corporate) ||
      ids.includes(generalSurvey.enShort.answer3Corporate) ||
      ids.includes(generalSurvey.esShort.answer3Corporate) ||
      ids.includes(generalSurvey.frShort.answer3Corporate)
      ? '1' : '0';
  } else {
    choice = '000';
  }

  return getVariant('customerExperience', language, version, choice);
};

const evaluatingNextProcmat = (language, responses, customerId, version) => {
  let response = getCustomerResponses(responses, customerId)[0];

  let answerObj = response['answers'].find(
    (el) => 
      el.question.id === generalSurvey.enLong.questionSegments ||
      el.question.id === generalSurvey.esLong.questionSegments ||
      el.question.id === generalSurvey.frLong.questionSegments ||
      el.question.id === generalSurvey.enShort.questionSegments ||
      el.question.id === generalSurvey.esShort.questionSegments ||
      el.question.id === generalSurvey.frShort.questionSegments
  );

  let choice = '';
  if (answerObj !== 'undefined') {
    let ids = answerObj.answer.ids;
    choice += ids.includes(generalSurvey.enLong.answer1Retail) ||
      ids.includes(generalSurvey.esLong.answer1Retail) ||
      ids.includes(generalSurvey.frLong.answer1Retail) ||
      ids.includes(generalSurvey.enShort.answer1Retail) ||
      ids.includes(generalSurvey.esShort.answer1Retail) ||
      ids.includes(generalSurvey.frShort.answer1Retail)
      ? '1' : '0';
    choice += ids.includes(generalSurvey.enLong.answer2SME) ||
      ids.includes(generalSurvey.esLong.answer2SME) ||
      ids.includes(generalSurvey.frLong.answer2SME) ||
      ids.includes(generalSurvey.enShort.answer2SME) ||
      ids.includes(generalSurvey.esShort.answer2SME) ||
      ids.includes(generalSurvey.frShort.answer2SME)
      ? '1' : '0';
    choice += ids.includes(generalSurvey.enLong.answer3Corporate) ||
      ids.includes(generalSurvey.esLong.answer3Corporate) ||
      ids.includes(generalSurvey.frLong.answer3Corporate) ||
      ids.includes(generalSurvey.enShort.answer3Corporate) ||
      ids.includes(generalSurvey.esShort.answer3Corporate) ||
      ids.includes(generalSurvey.frShort.answer3Corporate)
      ? '1' : '0';
  } else {
    choice = '000';
  }

  return getVariant('processMaturity', language, version, choice);
};

function getCustomerResponses(responses, customerId) {
  return responses.filter((response) => {
    if (typeof response['parameters'] === 'undefined') return false;
    if (typeof response['parameters']['customer-id'] === 'undefined')
      return false;
    if (response['parameters']['customer-id'] !== customerId) return false;
    return true;
  });
}

const evaluatingNextGeneral = (language, version) => getVariant('general', language, version);
const evaluatingNextDigital = (language, version) => getVariant('digitalStrategy', language, version);
const evaluatingNextITArch = (language, version) => getVariant('itArchitecture', language, version);
const evaluatingNextDataArch = (language, version) => getVariant('dataArchitecture', language, version);
const evaluatingNextEsg = (language, version) => getVariant('esg', language, version);


export {
  evaluatingNextGeneral,
  evaluatingNextCustex,
  evaluatingNextProcmat,
  evaluatingNextDigital,
  evaluatingNextITArch,
  evaluatingNextDataArch,
  evaluatingNextEsg,
  isCustomerValid,
};
