/* eslint-disable react-hooks/exhaustive-deps */
import 'styles/App.css';
import {
  Routes,
  Route,
  useSearchParams,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Header from 'components/Header';
import React, { useEffect, useState } from 'react';
import API from 'services/api';
import IFrame from 'components/IFrame';
import MyRedirect from 'components/Redirect';
import surveysData from 'constants/surveys';
import Navbar from 'components/Navbar';
import Success from 'components/Success';
import Invalid from 'components/Invalid';
import Welcome from 'components/Welcome';
import Ending from 'components/Ending';

import {
  evaluatingNextCustex,
  evaluatingNextProcmat,
  evaluatingNextDataArch,
  evaluatingNextITArch,
  evaluatingNextDigital,
  evaluatingNextEsg,
  isCustomerValid,
  evaluatingNextGeneral,
} from 'services/surveyChecker';
import i18n from 'i18n';
import LanguagePicker from 'components/LanguagePicker';

const DEFAULT_GROUP = 'deg';

const isCompletedDefault = {
  general: false,
  'customer-experience': false,
  'process-maturity': false,
  'it-architecture': false,
  'data-architecture': false,
  'digital-strategy': false,
  'esg': false,
};

const isLoadingDefault = {
  general: false,
  'customer-experience': false,
  'process-maturity': false,
  'it-architecture': false,
  'data-architecture': false,
  'digital-strategy': false,
  'esg': false,
};

function App() {
  const { search, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [surveys, setSurveys] = useState(surveysData);

  const [isCompleted, setIsCompleted] = useState({ ...isCompletedDefault });
  const [isFetched, setIsFetched] = useState({ ...isLoadingDefault });
  const navigate = useNavigate();

  const customerId = searchParams.get('customer-id');
  const short = searchParams.get('short');
  const group = searchParams.get('group') || localStorage.getItem('group') || DEFAULT_GROUP;
  const language = searchParams.get('language') || localStorage.getItem('language');

  i18n.changeLanguage(language);

  if (!!customerId) localStorage.setItem('customer-id', customerId);
  if (!!group) localStorage.setItem('group', group);
  if (!!language) localStorage.setItem('language', language);

  let version = '';
  if (short === 'true') {
    version = 'short';
  } else if (short === 'false') {
    version = 'long';
  } else if (localStorage.getItem('version') === 'short') {
    version = 'short';
  } else {
    version = 'long';
  }
  localStorage.setItem('version', version);

  const redirectHandler = ({ data }) => {
    if (typeof data === 'string' && data.includes('redirect')) {
      const localCustomerId = localStorage.getItem('customer-id');
      const localGroup = localStorage.getItem('group');
      const localVersion = localStorage.getItem('version');
      const localLanguage = localStorage.getItem('language');

      const route =
        '/success' +
        '?language=' +
        localLanguage +
        '&group=' +
        localGroup +
        '&customer-id=' +
        localCustomerId +
        (localVersion === 'short' ? '&short=true' : '') +
        '&from=' +
        data.split(';')[1];

      navigate(route);

      let localIsFetched = { ...isFetched };
      localIsFetched['general'] = false;
      setIsFetched({ ...localIsFetched });
      if (!isCompleted['general']) fetchGeneral();
      else {
        let localIsFetched = { ...isFetched };
        localIsFetched['general'] = true;
        setIsFetched({ ...localIsFetched });
      }
    }
  };

  useEffect(() => {
    if (isFetched.general) {
      if (!isCompleted.general) navigate('/welcome' + search);

      let localIsCompleted = { ...isCompleted };
      let localIsFetched = { ...isFetched };

      Object.keys(surveys)
        .filter((survey) => survey !== 'general')
        .forEach((survey) => {
          if (!isCompleted[survey]) {
            API.get(
              `https://api2.survio.com/v2/api/survey/${surveys[survey].id}/responses`
            )
              .then((res) => {
                if (isCustomerValid(res.data, customerId)) {
                  localIsCompleted[survey] = true;
                  setIsCompleted({ ...localIsCompleted });
                }
              })
              .finally(() => {
                localIsFetched[survey] = true;
                setIsFetched({ ...localIsFetched });
              });
          }
        });
    }
  }, [isFetched.general]);

  useEffect(() => {
    if (!Object.values(isCompleted).includes(false))
      navigate('/ending' + search);
  }, [
    isCompleted['customer-experience'],
    isCompleted['data-architecture'],
    isCompleted['digital-strategy'],
    isCompleted['process-maturity'],
    isCompleted['it-architecture'],
    isCompleted['esg'],
    isCompleted.general,
  ]);

  const fetchGeneral = () => {
    const generalId = evaluatingNextGeneral(language, version);
    const url = `https://api2.survio.com/v2/api/survey/${generalId}/responses`;
    API.get(url).then((res) => {
      const responses = res.data;
      if (isCustomerValid(responses, customerId)) {
        let localSurveys = { ...surveys };
        localSurveys['general'].id = generalId;
        localSurveys['digital-strategy'].id = evaluatingNextDigital(language, version);
        localSurveys['it-architecture'].id = evaluatingNextITArch(language, version);
        localSurveys['data-architecture'].id = evaluatingNextDataArch(language, version);
        localSurveys['esg'].id = evaluatingNextEsg(language, version);
        localSurveys['customer-experience'].id = evaluatingNextCustex(
          language,
          responses,
          customerId,
          version
        );
        localSurveys['process-maturity'].id = evaluatingNextProcmat(
          language,
          responses,
          customerId,
          version
        );
        setSurveys({ ...localSurveys });
        setIsCompleted({ ...isCompleted, general: true });
        setIsFetched({ ...isFetched, general: true });
      } else {
        let localSurveys = { ...surveys };
        localSurveys['general'].id = generalId;
        setSurveys({ ...localSurveys });
        let localIsFetched = { ...isFetched };
        Object.keys(localIsFetched).forEach((item) => {
          localIsFetched[item] = true;
        });
        setIsFetched({ ...localIsFetched });
      }
    });
  };

  useEffect(() => {
    if (language && !isCompleted.general) fetchGeneral();
    window.addEventListener('message', redirectHandler);
    return () => window.removeEventListener('message', redirectHandler);
  }, []);

  if(language === null) 
    return <LanguagePicker language={language}/>;

  if ((customerId === null || customerId === '') && pathname !== '/redirect')
    return <Invalid />;

  return (
    <div className='App'>
      <Header />
      <div className='d-flex fluid'>
        <Routes>
          <Route
            path='/'
            element={
              <Navbar
                items={surveys}
                isCompleted={isCompleted}
                isFetched={isFetched}
              />
            }
          >
            {Object.values(surveys).map((survey, i) => (
              <Route
                key={i}
                path={survey.route}
                element={
                  isCompleted[survey.route] ? (
                    <Success surveyTitle={survey.name} />
                  ) : (
                    <IFrame
                      surveyTitle={survey.name}
                      surveyId={survey.id}
                      customerId={customerId}
                      group={group}
                      items={surveys}
                      isCompleted={isCompleted}
                    />
                  )
                }
              />
            ))}
            <Route path='success' element={<Success />} />
            <Route path='redirect' element={<MyRedirect />} />
          </Route>
          <Route path='/welcome' element={<Welcome />} />
          <Route path='/ending' element={<Ending short={short}/>} />
          <Route path='*' element={<Navigate to={'/general' + search} />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
