// Variant short/long are the same

const surveysVariations = {
  general: {
    en: {
      short: 'Y9L4I6D9X6C2J8S9H',
      long: 'P0E9E7T4A9E6P1I3N',  
    },
    es: {
      short: 'P1D9U3H7I9X2Q3V7G',
      long: 'J2O0A7A0X1H1O6W4E',
    },
    fr: {
      short: 'E6C9Y1A7T9P3Q5Z9U',
      long: 'N4R1F8R3V2O5O3U2T',
    }
  },
  customerExperience: {
    en: {
      short: {
        '000': 'L2D6H4M9L3W2K9L8X', // variant A
        '001': 'R0G0A7I0O2U6S3C0Y', // variant D
        '010': 'C4E7O1B2T9T9H9H6X', // variant C
        '011': 'I4A9O5D1B4K9S6L0W', // variant F
        '100': 'N9V4D5B9G9E9U2J7C', // variant G
        '101': 'Q6F1R7I7B8R7O4Y9A', // variant E
        '110': 'G5J4A7Z4K4B5C1H9X', // variant B
        '111': 'L2D6H4M9L3W2K9L8X', // variant A
      },
      long: {
        '000': 'S1X1L5A9R5D9W2U7E', // variant A
        '001': 'I3A2V9A2I0L5D9X2M', // variant D
        '010': 'Y3F5R8J3R3C8E9X9O', // variant C
        '011': 'O4E6D9L2P3A5Q2W9G', // variant F
        '100': 'S5W4G2Q0T3R8T0F1X', // variant G
        '101': 'M5O5K9C4M2B7R4X6F', // variant E
        '110': 'R8E2W4D2N5D3A5C8E', // variant B
        '111': 'S1X1L5A9R5D9W2U7E', // variant A
      },
    },
    es: {
      short: {
        '000': 'C7K1W4K7S9F5T0L9H', // variant A
        '001': 'X5Q5T4L0J7X5C8M7L', // variant D
        '010': 'I7J4R9H6W4T2Y0U8D', // variant C
        '011': 'O6R7B7A1R6L2A6B9R', // variant F
        '100': 'L8A2X2B8I6D1L3A9C', // variant G
        '101': 'C4K7M6H8F4N9W9K3F', // variant E
        '110': 'Q9S6I7B3B9L7D8E3J', // variant B
        '111': 'C7K1W4K7S9F5T0L9H', // variant A
      },
      long: {
        '000': 'J8K6D1K9Y3B8A2S6M', // variant A
        '001': 'M7M0J8E2N3A9E5E5S', // variant D
        '010': 'U7T6R8Z6Q2S4N8N9L', // variant C
        '011': 'S1Q2L6O5H3D3D6U9H', // variant F
        '100': 'J6H6I9A3M3P1C5G7D', // variant G
        '101': 'M5R2X0L9J9H7R5Q9H', // variant E
        '110': 'B9D2L2N1H7Y2A7C8H', // variant B
        '111': 'J8K6D1K9Y3B8A2S6M', // variant A
      },
    },
    fr: {
      short: {
        '000': 'G9C5T6G3X5S1Z2K4R', // variant A
        '001': 'Q1V9T3Y1Y8O8S5O1T', // variant D
        '010': 'E0D9X5A9H7N7X6I3B', // variant C
        '011': 'T8H7D3M1Y2D6G6E4O', // variant F
        '100': 'E5U5U3T3I0S0A2J0Q', // variant G
        '101': 'M5J0O3H0N2X4J5B1C', // variant E
        '110': 'M6L1I4N7T0Z4J2Q8F', // variant B
        '111': 'G9C5T6G3X5S1Z2K4R', // variant A
      },
      long: {
        '000': 'A0X6K9R2L2X9R5S9Q', // variant A
        '001': 'Y6E3Y5N6O2E1M8L5D', // variant D
        '010': 'D5B7E2I9O7G1V9U2H', // variant C
        '011': 'I2I9S6E7T1B8F9T9F', // variant F
        '100': 'D5F4T0A1J2E9J9S7K', // variant G
        '101': 'W0S3R5C1X7A3Q4M4W', // variant E
        '110': 'A1K6P2X6F4F5V5D8F', // variant B
        '111': 'A0X6K9R2L2X9R5S9Q', // variant A
      }
    }
  },
  processMaturity: {
    en: {
      short: {
        '000': 'R9B7G6T9P0L7X1A6O', // variant A
        '001': 'I6N6G1S0O0K0P0Y9P', // variant D
        '010': 'C0W6W4Y6I2U5C5D2A', // variant C
        '011': 'J4T9A7E8D3E1K4X5N', // variant F
        '100': 'W2B3M7H2R2B8E3Y3E', // variant G
        '101': 'V1W6K9P3W6A7F5U5N', // variant E
        '110': 'A3H8C9S9S3B8M3W7I', // variant B
        '111': 'R9B7G6T9P0L7X1A6O', // variant A
      },
      long: {
        '000': 'L0S2F5H9G9N6T7D2X', // variant A
        '001': 'T0R9S1N9Q8J3X9O5W', // variant D
        '010': 'B8M0A7A3V9X2Y6S1T', // variant C
        '011': 'C5V4E8S9P6S2J7D8U', // variant F
        '100': 'H3U2F3I5B4L6D5N4L', // variant G
        '101': 'A2L9M7P4G5Y5W2F8W', // variant E
        '110': 'G3F3W5E0U6S5U5F4A', // variant B
        '111': 'L0S2F5H9G9N6T7D2X', // variant A
      }
    },
    es: {
      short: {         
        '000': 'J2L1H1W9C9O9W7K3L', // variant A
        '001': 'C9H4G4A5Y7I7I8L5A', // variant D
        '010': 'L6V7I5Z7J5K3J8Y5G', // variant C
        '011': 'L2E8U8U1A3C6X5J0T', // variant F
        '100': 'R9C9F2L6U7D8U6Z0Y', // variant G
        '101': 'J6Q2T6Z1R5H7P2B4C', // variant E
        '110': 'O5D9C4D7X5L5E9J9I', // variant B
        '111': 'J2L1H1W9C9O9W7K3L', // variant A,
      },
      long: {
        '000': 'F3A1T6H3N6R1Y4C4O', // variant A
        '001': 'P5L8E8K8J8J2I2R3T', // variant D
        '010': 'R6B3E8O2P7P5A1A6O', // variant C
        '011': 'M5B2D9V5S3J6Y8A8K', // variant F
        '100': 'A1D2V9L0A4E8Y1S3R', // variant G
        '101': 'W8G6W6N4G8H5T6K9R', // variant E
        '110': 'J5L6G2T6A6R2I2H2U', // variant B
        '111': 'F3A1T6H3N6R1Y4C4O', // variant A
      }
    },
    fr: {
      short: {         
        '000': 'F1O1Q7R2M1L4X1L9V', // variant A
        '001': 'K9K9P2F2V9Y9P4A2P', // variant D
        '010': 'N8D1W6Q3Q6D6D7T5E', // variant C
        '011': 'T1Q9M2L6M6V1P8W7O', // variant F
        '100': 'K5E8Q8Y3C8T6H4A4N', // variant G
        '101': 'R5C6D3Y6C4D2C5X6M', // variant E
        '110': 'T1G9Y4M8S5I6I3N2Y', // variant B
        '111': 'F1O1Q7R2M1L4X1L9V', // variant A
      },
      long: {
        '000': 'B5P7E9D7I9H3L1A8L', // variant A
        '001': 'J4S9P5S7X6W2N7I2E', // variant D
        '010': 'L9I7B8U9V0P8N9S9H', // variant C
        '011': 'J1K7O7H5L5H7Q1J0B', // variant F
        '100': 'C8D1C9R1B9U6U0V2Z', // variant G
        '101': 'H1X9M4I0Y1N5B9O5Y', // variant E
        '110': 'D3L8O8K2J5P3S6R3T', // variant B
        '111': 'B5P7E9D7I9H3L1A8L', // variant A
      }
    }
  },
  digitalStrategy: {
    en: {
      short: 'M9M4Q9I7N8N5W1W3J',
      long: 'G9M6B6V3Q4P2I9A0Q',
    },
    es: {
      short: 'T7A8J2R4R5I9W3B2C',
      long: 'L7N9A3I5P7N5O2X1Y',
    },
    fr: {
      short: 'X1J9A2A5R3V9N4S9J',
      long: 'S9C8G5G9E5L6R2S5I',
    }
  },
  itArchitecture: {
    en: {
      short: 'I5O8H1M0C0N9H4X3K',
      long: 'A3P6C9G1F9G9E0P5P',
    },
    es: {
      short: 'L3U7I7Z6S8W7N0Z9W',
      long: 'X4Y6V2J6Q9E7U6N5H' 
    },
    fr: {
      short: 'M6B9W6D9V3V0B6F2C',
      long: 'R1S1B3O7H6N0L1F3P',
    }
  },
  dataArchitecture: {
    en: {
      short: 'W9F9G2P5E6V5L4L7S',
      long: 'L3J7M5Q8O4T0V2F2H',  
    },
    es: {
      short: 'R5C4R5V9J0B6T0W2J',
      long: 'R3N8O8O8Y4G5A8P8F',
    },
    fr: {
      short: 'P2P3D7A4E8X9F9Q3P',
      long: 'Q3F2Q1E9Z8N2J6K0S',
    }
  },
  esg: {
    en: {
      short: 'F8I5L8G5X8F3P4V8N',
      long: 'X4E3D3T1D1U6C2Y4P',
    },
    es: {
      short: 'Q5F5Y9G1P0I8D5I7Z',
      long: 'A7Q1L4G4C8U1A7I2G',
    },
    fr: {
      short: 'X7A1P1F3B3S2O2T5S',
      long: 'S6Q3J5N5U5X6X4V3R',
    }
  },
}

const generalSurvey = {
  enLong: {
    questionSegments: 63552007,
    answer1Retail: 173689890,
    answer2SME: 173689891,
    answer3Corporate: 173689892,
  },
  enShort: {
    questionSegments: 68241508,
    answer1Retail: 184820010,
    answer2SME: 184820011,
    answer3Corporate: 184820012,
  },
  esLong: {
    questionSegments: 67273167,
    answer1Retail: 182601918,
    answer2SME: 182601919,
    answer3Corporate: 182601920,
  },
  esShort: {
    questionSegments: 68241484,
    answer1Retail: 184819972,
    answer2SME: 184819973,
    answer3Corporate: 184819974,
  },
  frLong: {
    questionSegments: 67669655,
    answer1Retail: 183531971,
    answer2SME: 183531972,
    answer3Corporate: 183531973,
  },
  frShort: {
    questionSegments: 67263070,
    answer1Retail: 182575195,
    answer2SME: 182575196,
    answer3Corporate: 182575197,
  },
}

const getVariant = (surveyCode, language, version = 'long', generalAnswers = '') => {
  if(language !== 'en' && language !== 'es' && language !== 'fr') 
    throw new Error(`Language '${language}' does not exist`);

  switch(surveyCode) {
    case 'general': {
      return surveysVariations[surveyCode][language][version];
    }

    case 'customerExperience':
    case 'processMaturity': {
      return surveysVariations[surveyCode][language][version][generalAnswers];
    }

    case 'digitalStrategy':
    case 'itArchitecture':
    case 'dataArchitecture':
    case 'esg': {
      return surveysVariations[surveyCode][language][version];
    }
    default: {
      throw new Error(`${surveyCode} is not valid code`);
    }
  }
}

export { getVariant, generalSurvey };