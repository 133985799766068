import styles from 'styles/components/Header.module.css';
import logo from 'assets/img/logo.svg';
import { useTranslation } from 'react-i18next';

function Header({ borderless }) {
  const { t } = useTranslation();

  return (
    <div
      className={styles.Header + ' ' + (borderless ? styles.borderless : '')}
    >
      <img src={logo} alt='' />
      {t('HEADER_TITLE')}
    </div>
  );
}

export default Header;
