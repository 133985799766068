import { useSearchParams } from 'react-router-dom';

function Redirect() {
  const [searchParams] = useSearchParams();

  const from = searchParams.get('from') ?? ' ';

  window.top.postMessage('redirect;' + from, '*');

  return (<></>);
}

export default Redirect;
