import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function IFrame({ surveyTitle, surveyId, customerId, isCompleted, group }) {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isCompleted.general && pathname !== '/general')
      navigate('/general' + search);
  }, []);

  return (
    <iframe
      style={{
        backgroundColor: 'white',
      }}
      title={surveyTitle}
      id='iframeX6D3A1V8A8F1F9Y6J'
      src={`https://www.survio.com/survey/i/${surveyId}?customer-id=${customerId}&group=${group}`}
      height='auto'
      width='100%'
      frameBorder='0'
    ></iframe>
  );
}

export default IFrame;
