import { NavLink, Outlet, useLocation } from 'react-router-dom';
import stylesNavbar from 'styles/components/Navbar.module.css';
import stylesLink from 'styles/components/Link.module.css';
import Spinner from 'components/Spinner';
import check from 'assets/img/check.svg';
import { useTranslation } from 'react-i18next';

function Navbar({ items, isCompleted, isFetched }) {
  const { search } = useLocation();
  const { t } = useTranslation();

  const className = (isActive) => {
    let className = '';
    className += stylesLink.Link + ' ';
    className += (isActive ? stylesLink.active : '') + ' ';
    className += (!isCompleted.general ? 'disabled' : '') + ' ';
    return className;
  };

  const generalClassName = (isActive) => {
    let className = '';
    className += stylesLink.Link + ' ';
    className += (isActive ? stylesLink.active : '') + ' ';
    return className;
  };

  return (
    <>
      <nav className={stylesNavbar.Navbar}>
        {Object.values(items).map((item, i) => (
          <NavLink
            key={i}
            className={item.route === 'general' ? generalClassName : className}
            to={item.route + search}
          >
            {t(item.name)}
            {isCompleted[item.route] ? (
              <img src={check} alt='Spinner' />
            ) : !isFetched[item.route] ? (
              <Spinner />
            ) : (
              ''
            )}
          </NavLink>
        ))}
      </nav>
      <Outlet />
    </>
  );
}

export default Navbar;
