import styles from 'styles/components/Success.module.css';
import check from 'assets/img/check.svg';
import surveysData from 'constants/surveys';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Success({ surveyTitle }) {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const from = searchParams.get('from');
  const fromTitle = from && surveysData[from] ? surveysData[from].name : '';
  const title = surveyTitle ?? fromTitle ?? 'Completed!';

  if (title === surveyTitle) searchParams.delete('from');

  return (
    <div className={styles.Success}>
      <img src={check} alt='Check' />
      <div className={styles.Title}>{t(title)}</div>
      <div className={styles.Description}>{t('SUCCESS_TEXT')}</div>
    </div>
  );
}

export default Success;
