const surveys = {
  'general': { 
    id: 'P0E9E7T4A9E6P1I3N',
    name: 'GENERAL',
    route: 'general',
  }, 
  'digital-strategy': { 
    id: 'G9M6B6V3Q4P2I9A0Q',
    name: 'DIGITAL_STRATEGY',
    route: 'digital-strategy',
  },
  'customer-experience': { 
    id: 'S5W4G2Q0T3R8T0F1X',
    name: 'CUSTOMER_EXPERIENCE',
    route: 'customer-experience',
  },
  'process-maturity': { 
    id: 'H3U2F3I5B4L6D5N4L',
    name: 'PROCESS_MATURITY',
    route: 'process-maturity',
  },  
  'data-architecture': 
  {
    id: 'L3J7M5Q8O4T0V2F2H',
    name: 'DATA_ARCHITECTURE',
    route: 'data-architecture',
  },
  'it-architecture': { 
    id: 'A3P6C9G1F9G9E0P5P',
    name: 'IT_ARCHITECTURE',
    route: 'it-architecture',
  },
  'esg': {
    id: 'X4E3D3T1D1U6C2Y4P',
    name: 'ESG',
    route: 'esg',
  }
};

export default surveys;