import { useLocation, useNavigate } from 'react-router-dom';
import styles from 'styles/components/LanguagePicker.module.css';

function LanguagePicker({ language }) {
  const { search } = useLocation();
  const navigate = useNavigate();

  const navigateToEn = () => {
    let url = `/welcome${search}`
    localStorage.setItem('language', 'en')
    navigate(url);
  };

  const navigateToEs = () => {
    let url = `/welcome${search}`
    localStorage.setItem('language', 'es')
    navigate(url);
  };

  const navigateToFr = () => {
    let url = `/welcome${search}`
    localStorage.setItem('language', 'fr')
    navigate(url);
  };

  return (
    <div className={styles.LanguagePicker}>
      <h1>Choose your language:</h1>
      <div className={styles.Buttons}>
        <button className={styles.Button} onClick={navigateToEn}>
          English
        </button>
        <button className={styles.Button} onClick={navigateToEs}>
          Español
        </button>
        <button className={styles.Button} onClick={navigateToFr}>
          Français
        </button>
      </div>
    </div>
  );
}

export default LanguagePicker;
